@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@tabs' as *;
@use '@button' as *;
@use '@queries' as *;

.total__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-right {
    display: flex;
    align-items: center;
    gap: 6px;

    .violet-circle {
      @include singleFixedSize(4px);
      border-radius: 50%;
      background: var(--global-purple600);
    }
  }

  &-text {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
  }

  &-title,
  &-subtitle {
    color: var(--cst-cards-checkout-info-item);
    @include typography-s;
    @include weight-medium;

    @include min-428-break {
      @include typography-base;
    }
  }

  &-subtitle {
    color: var(--cst-cards-checkout-info-secondary-item);
  }
}
